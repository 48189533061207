import React from 'react'
import { css } from '@emotion/core'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

// Components
import Hero from '../components/hero'
import ContactForm from '../components/contactForm'

const KontaktPage = () => (
  <Layout>
    <Helmet>
      <title>Ekko App - Kontakt</title>
    </Helmet>
    <Hero
      title="Har du spørgsmål?"
      subTitle="Du er altid velkommen til at kontakte os. Send os en besked med formularen nedenfor eller ring til os på 71 74 72 10"
    />
    <div css={container} className={'container'}>
      <h1 className="u_mb-sm">Skriv til os</h1>
      <ContactForm />
    </div>
  </Layout>
)

function container() {
  return css`
    padding-top: 150px;

    p.lead {
      max-width: 800px;
    }
  `
}

export default KontaktPage
